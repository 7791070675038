<template>
	<div class="main-menu d-flex justify-content-between flex-column">
		<div class="opt-start">
			<div class="pt-4 pb-2">
				<img src="@/assets/logo.png" alt="" width="30">
			</div>
			<div class="df-link py-2" @click="enlace('home')">
				<a :class="cssLink('home')"><icon icon="fa-house" /></a>
			</div>
			<div class="df-link py-2" @click="enlace('form.example')">
				<a :class="cssLink('form.example')"><icon icon="address-card" /></a>
			</div>
			<div class="df-link py-2" @click="enlace('form.select')">
				<a :class="cssLink('form.select')"><icon icon="fa-edit" /></a>
			</div>
			<div class="df-link py-2" @click="enlace('form.list')">
				<a :class="cssLink('form.list')"><icon icon="fa-table-list" /></a>
			</div>
			<div class="df-link py-2" @click="enlace('dash')">
				<a :class="cssLink('dash')"><icon icon="chart-pie" /></a>
			</div>
		</div>
		<div class="opt-end">
			<div class="df-link py-2" v-if="conexion.status">
				<router-link to="/about" class="has-text-icon has-text-white"><icon icon="wifi-strong" /></router-link>
			</div>
			<div class="df-link py-2 isfocus" v-else>
				<router-link to="/about" class="has-text-icon has-text-white"><icon icon="plug-circle-xmark" /></router-link>
			</div>
			<div class="df-link py-2 has-background-dark">
				<router-link to="/about" class="has-text-icon has-text-white"><icon icon="fa-power-off" /></router-link>
			</div>
		</div>
	</div>
	<section class="main-content">
		<div class="container">
			<!-- {{ conexion.status }} -->
			<router-view/>
		</div>
	</section>
	<footer id="fooler" class="has-background-light py-2 is-hidden">Todos los derechos reservados.</footer>
</template>
<script>
import { useConexionStore } from '@/stores/conexion';
export default {
	data() {
		return {
			conexion: useConexionStore()
		}
	},
    methods: {
		cssLink: function(arg){
			return (this.$route.name == arg)? 'has-text-icon is-size-4 router-link-active': 'has-text-icon is-size-4';
		},
        enlace: function(arg){
            this.$router.push({'name': arg});
        }
    }
}
</script>
<style>
	html {overflow-y: hidden !important}
	.main-menu {
		/* background:#381A50; */
		background:#007F73;
		position: fixed;
		top:0;
		left:0;
		height:100vh;
		width:70px;
		z-index: 39;
	}
	.df-link {cursor: pointer}
	.main-menu .opt-start .df-link:hover {background: #FFFFFF33 !important; color:#FFF}
	/* .main-top {margin-left: 70px; margin-top:1rem; position: fixed;} */
	.main-content {
		margin-left: 70px;
		overflow-y: auto;
		height: 100vh;
	}
	#fooler {margin-left: 70px; position:fixed; left:0; bottom:0; right:0; border-top:1px solid #CCC;}
	.has-text-icon {color:#FFFFFF55}
	a.router-link-active {color:#FFF !important}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
	nav a.router-link-exact-active {
		color: #42b983;
	}
	@keyframes focusFx {
		0% {background-color:#FFFFFF00}
		70% {background-color:#FFFFFF55}
		100% {background-color:#FFFFFF00}
	}
	.isfocus {animation-name: focusFx; animation-duration: 1.2s; animation-iteration-count: infinite}

</style>
