import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const routes = [
	{path: '/', name: 'home', component: () => import('../views/HomeView.vue')},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	{path: '/form/example', name: 'form.example', component: () => import('../views/ExampleView.vue')},
	{path: '/form/select', name: 'form.select', component: () => import('../views/SelectFormView.vue')},
	{path: '/form/write', name: 'form.write', component: () => import('../views/WriteFormatoView.vue')},
	{path: '/formatos', name: 'form.list', component: () => import('../views/TableDataView.vue')},
	{path: '/dashboard', name: 'dash', component: () => import('../views/DashboardView.vue')},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

NProgress.configure({showSpinner: false});

router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router
